<template>
  <div class="main">
    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">
      <router-view> </router-view>
      <div class="textbox">
        <!-- 新闻资讯 -->
        <div>
          <div class="textbox">
            <div class="textleft">
              <el-carousel class="smallbanner" :interval="3000" :height="bannerHeightl + 'px'">
                <el-carousel-item v-for="(item, i) in smallLB" :key="i">
                  <a href="javascript:void(0)">
                    <img
                      style="display: block; width: 100%"
                      class="bannerImg"
                      :src="item.Img"
                      alt=""
                      @click="GoLunBoUrl(item.Description)"
                  /></a>
                </el-carousel-item>
              </el-carousel>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="最新资讯" name="-1"> </el-tab-pane>
                <el-tab-pane
                  v-for="(item, i) in CateList"
                  :key="i"
                  :label="item.Name"
                  :name="'name' + i"
                ></el-tab-pane>
              </el-tabs>
              <div v-for="(item, i) in NewsList" :key="i">
                <el-card
                  shadow="hover"
                  style="margin-bottom: 20px; cursor: pointer"
                  class="box-card"
                  v-if="item.CateName == '优惠政策'||item.CateName == '产业政策'"
                >
                  <div class="item" @click="InformationDetails(item)">
                    <img :src="item.FirstImgUrl" mode="aspectFill" alt="" />
                    <div class="noimgbox">
                      <h3>
                        {{ item.Title }}
                      </h3>
                      <p>
                        {{ item.NewsKey }}
                      </p>
                      <div class="itembottom">
                        <span> {{ item.PolicyLevelName }}</span
                        ><span> 申报开始时间 {{ item.StarTime }} </span>
                      </div>
                    </div>
                  </div>
                </el-card>

                <el-card
                  shadow="hover"
                  style="margin-bottom: 20px; cursor: pointer"
                  class="box-card"
                  v-else
                >
                  <div class="item" @click="InformationDetails(item)">
                    <img :src="item.FirstImgUrl" mode="aspectFill" alt="" />
                    <div class="newsbox">
                      <h3>
                        {{ item.Title }}
                      </h3>
                      <p>
                        {{ item.Intro }}
                      </p>
                      <div class="itembottom">
                        <span>{{ item.PublishTime }}</span
                        ><span style="color: #366cb3; margin-left: 30px">{{
                          item.CateName
                        }}</span>

                        <span
                          v-if="item.Type == 1"
                          style="
                            position: absolute;
                            color: #ff6900;
                            right: 10px;
                          "
                          >自贸办</span
                        >
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
            <div class="textright">
              <el-card style="margin-bottom: 10px" class="box-card">
                <h4>最新资讯</h4>
                <div
                  v-for="(item, i) in NewNewsList"
                  :key="i"
                  class="items"
                  style="cursor: pointer"
                  @click="InformationDetails(item)"
                >
                  <span
                    style="font-size: 20px; font-weight: 800; color: #366cb3"
                    >·</span
                  >
                  <span
                    style="
                      width: 200px;
                      height: 32px;
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      line-clamp: 1;
                      -webkit-box-orient: vertical;
                    "
                    >{{ item.Title }}</span
                  >
                  <i>{{ item.PublishTime }}</i>
                </div>
              </el-card>

              <el-card style="margin-bottom: 10px" class="box-card">
                <h4>最新注册企业</h4>
                <div
                  v-for="(item, i) in NewCompanyList"
                  :key="i"
                  class="items"
                  style="cursor: pointer"
                  @click="getComInfor(item.CSysID)"
                >
                  <span
                    style="
                      font-size: 20px;
                      font-weight: 800;
                      color: #366cb3;
                      padding-right: 3px;
                    "
                    >·</span
                  >
                  {{ item.CName }}
                </div>
              </el-card>

              <el-card style="margin-bottom: 20px" class="box-card">
                <div class="zptitle">最新招聘信息</div>
                <div
                  v-for="(item, i) in NewZPList"
                  :key="i"
                  class="itemZp"
                  style="cursor: pointer"
                  @click="getZPInfor(item.SysID)"
                >
                  <img :src="item.CompanyLogo" alt="" />
                  <div class="companinfor">
                    <span style="font-weight: 700"> {{ item.Title }}</span>
                    <span style="font-size: 13px"> {{ item.CompanyName }}</span>
                    <i>{{ item.SalaryL }}-{{ item.SalaryH }}</i>
                  </div>
                </div>
              </el-card>

              <div
                class="itemlct"
                v-if="LCTobj.length"
              
              >
                <el-carousel height="80px" indicator-position='none'>
                  <el-carousel-item v-for="(item,i) in LCTobj" :key="i"  >
                    <img :src="item.Img" alt=""  @click="GoLCTUrl(item.Description)" />
                  </el-carousel-item>
                </el-carousel>
               
              </div>
            </div>
          </div>
        </div>
        <!-- 企业互动 -->
        <div>
          <div class="index-toppic2" style="margin-top: 20px">企业互动</div>
          <div class="sr-grid has-gap36 partner">
            <div class="sr-row">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6">
                    <!--Accordion wrapper-->
                    <div
                      class="accordion about-content"
                      id="accordionColumn1"
                      role="tablist"
                      aria-multiselectable="true"
                      style=""
                    >
                      <div
                        class="mybox"
                        style="
                          padding: 10px 0px 6px 18px;
                          margin-top: 10px;
                          margin-right: 10px;
                          border-bottom: 1px solid #3fbcbb;
                        "
                        v-for="(item, i) in InteractionGList"
                        :key="i"
                      >
                        <a
                          @click="getGinfor(item.SysID)"
                          gong
                          style="
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            font-size: 17px;
                            font-weight: 700;
                            color: #323232;
                            -webkit-box-orient: vertical;
                          "
                          href="javascript:void(0)"
                        >
                          <span style="color: #000">
                            <img
                              style="
                                display: inline-block;
                                height: 32px;
                                width: 32px;
                                vertical-align: middle;
                              "
                              class=""
                              src="../assets/img/gong.png"
                            />
                          </span>
                          {{ item.Title }}
                        </a>
                        <a
                          @click="getGinfor(item.SysID)"
                          href="javascript:void(0)"
                          style="
                            display: inline-block;
                            width: 100%;
                            margin-top: 10px;
                            font-weight: 500;
                            font-size: 13px;
                          "
                        >
                          <span
                           class="comnames"
                            style="
                              color: #000;
                              float: left;
                              font-size: 14px;
                              margin-left: 10px;
                              text-overflow: -o-ellipsis-lastline;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              line-clamp: 1;
                              -webkit-box-orient: vertical;
                              width: 200px;
                            "
                            >{{ item.CompanyName }}</span
                          >
                          <span class="moneyss" style="color: #ff6633; margin-left: 30px"
                            >报价：{{ item.Money }}</span
                          >
                          <span
                          class="uutime"
                            style="
                              float: right;
                              color: #000;
                              font-size: 14px;
                              font-weight: 400;
                              margin-left: 30px;
                            "
                            >{{ item.PublishTime }}</span
                          ></a
                        >
                      </div>

                      <!-- Item 01 -->
                    </div>
                    <!-- Accordion wrapper End -->
                  </div>
                  <div class="col-lg-6" style="">
                    <!--Accordion wrapper-->
                    <div
                      class="accordion about-content"
                      id="accordionColumn2"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div
                      class="mybox"
                        style="
                          padding: 10px 0 6px 3px;
                          margin-top: 10px;
                          width: 98%;
                          border-bottom: 1px solid #3fbcbb;
                        "
                        v-for="(item, i) in InteractionXList"
                        :key="i"
                      >
                        <a
                          style="
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            line-clamp: 1;
                            font-size: 17px;
                            font-weight: 700;
                            color: #323232;
                            width: 98%;
                            -webkit-box-orient: vertical;
                          "
                          @click="getGinfor(item.SysID)"
                        >
                          <span style="color: #000">
                            <img
                              style="
                                display: inline-block;
                                height: 32px;
                                width: 32px;
                                vertical-align: middle;
                              "
                              class=""
                              src="../assets/img/xu.png"
                          /></span>
                          {{ item.Title }}
                        </a>
                        <a
                          @click="getGinfor(item.SysID)"
                          style="
                            display: inline-block;
                            margin-top: 10px;
                            width: 100%;
                            font-size: 14px;
                          "
                        >
                          <span
                          class="comnames"
                            style="
                              color: #000;
                              float: left;
                              font-size: 14px;
                              margin-left: 10px;
                              text-overflow: -o-ellipsis-lastline;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              line-clamp: 1;
                              -webkit-box-orient: vertical;
                              width: 200px;
                            "
                            >{{ item.CompanyName }}</span
                          >
                          <span class="moneyss" style="color: #ff6633; margin-left: 30px"
                            >预算：{{ item.Money }}</span
                          ><span
                          class="myrighttime"
                            style="float: right; color: #000; font-size: 14px;margin-right: 10px;"
                            >{{ item.PublishTime }}</span
                          ></a
                        >
                      </div>
                    </div>
                    <div class="rowss" style="width: 100%; height: 30px">
                      <a
                        href="/interaction"
                        style="
                          float: right;
                          height: 30px;
                          width: 120px;
                          text-align: center;
                          line-height: 30px;
                          font-weight: 500;
                          margin-left: 60px;
                          color: #3273dc;
                          font-size: 13px;
                        "
                        >查看更多>></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="index-toppic2">产业载体</div>
            <div class="sr-row">
              <div class="sr-col-3">
                <div
                  class="sr-card"
                  v-for="(item, index) in BuildingList"
                  :key="index"
                >
                  <div class="sr-card-image">
                    <img
                      v-if="item.FileList.length > 0"
                      :src="item.FileList[0].URL"
                      alt=""
                      @click="GetBuildingIn(item.SysID)"
                      style="cursor: pointer"
                    />
                    <img v-else src="" alt="" />
                  </div>
                  <div class="sr-card-contents">
                    <h3
                      @click="GetBuildingIn(item.SysID)"
                      style="cursor: pointer"
                    >
                      {{ item.Name }}
                    </h3>
                    <div
                      class="tags"
                      style="
                        height: 46px;
                        line-height: 22px;
                        font-size: 14px;
                        overflow: hidden;
                        color: #999;
                      "
                    >
                      <img
                        style="
                          display: inline-block;
                          height: 20px;
                          width: 20px;
                          vertical-align: middle;
                        "
                        class=""
                        src="../assets/img/enterprise.png"
                      /><span> {{ item.CompanyCount }}</span>
                      <img
                        class=""
                        style="
                          display: inline-block;
                          height: 20px;
                          width: 20px;
                          vertical-align: middle;
                        "
                        src="../assets/img/Station.png"
                      /><span> {{ item.OverplusCount }}</span> <br /><span
                        style="margin-top: 5px; display: inline-block"
                        >{{ item.CateName }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="sr-card-footer"
                    style="
                      background-color: #f3f7fb;
                      padding: 8px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-top: 4px;
                      font-size: 14px;
                    "
                  >
                    {{ item.Address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 企业服务 -->
          <div>
            <div class="index-toppic2">企业服务</div>
            <div class="sr-row">
              <div
                class="sr-card"
                v-for="(item, i) in ServeList"
                :key="i"
                @click="getServeInfor(item.SysID)"
              >
                <div class="sr-card-content">
                  <h3 style="cursor: pointer">{{ item.Title }}</h3>
                  <div class="tags">
                    <span>{{ item.CateName }}</span
                    ><span></span>
                  </div>
                  <p>{{ item.Intro }}</p>
                  <p>联系电话：{{ item.Tel }}</p>
                </div>
                <div class="sr-card-footer">
                  <img :src="item.Logo" style="object-fit: scale-down" class="fl" />

                  <div class="info">
                    <span class="company" style="color: #32a2ec">{{
                      item.CompanyName
                    }}</span>
                    <!-- <div class="record"></div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 合作伙伴 -->
          <div>
            <div class="index-toppic2">合作伙伴</div>
            <div class="sr-row">
              <span class="row_item" v-for="(item, i) in CompanyList" :key="i">
                <img
                  :src="item.CompanyLogo"
                  alt=""
                  style="object-fit: contain"
                  @click="getComInfor(item.CSysID)"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- body end -->
    </div>
  </div>
</template>

<script>
// 公共样式

import common from "../util/common.js";
import timeFormat from "../util/time.js";
import "../assets/css/common.css";
export default {
  props: ["windowWidth"],
  data() {
    return {
      height: "",
      bannerHeightl: "",
      activeName: "-1",
      NewsList: [],
      CateList: [],
      InteractionGList: [],
      InteractionXList: [],
      BuildingList: [],
      ServeList: [],
      CompanyList: [],
      NewNewsList: [],
      NewCompanyList: [],
      NewZPList: [],
      activeIndex: "1",
      drawer: false,
      // windowWidthl: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      smallLB: [],
      LCTobj: [],
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      console.log(val);

      if (val > 500) {
        that.drawer = false;
        that.bannerHeight = "300";
        that.bannerHeightl = "270";
      } else {
        that.bannerHeight = "120";
        that.bannerHeightl = "150";
      }
    },
  },
  methods: {
    imgLOad() {
      this.$nextTick(() => {
        this.bannerHeightl = this.$refs.bannerHeight[0].height;
        console.log(this.$refs.bannerHeight);
      });
    },
    //政策申报
    GoLCTUrl(url) {
      console.log(url);
      if (url != null && url != "" && common.GetFileHref(url)) {
        window.open(url);
      }
    },
    //轮播图列表api/SysNews/List
    getLCT() {
      let that = this;
      that
        .$post("api/SysNews/List", {
          State: true,
          Type: "首页政策申报",
          IsHome: true,
          Sort:'Sort ASC'
        })
        .then(function (res) {
          console.log(res);
          let data = res.Obj;
          if (!data.length) {
            return;
          }
          data.forEach((v) => {
            if (v.Img) {
              v.Img = that.urlOA + v.Img.substr(1);
            }
          });
          that.LCTobj = data;
        });
    },
    //轮播跳转
    GoLunBoUrl(url) {
      if (url != null && url != "" && common.GetFileHref(url)) {
        window.open(url);
      }
    },
    getLBsmail() {
      let that = this;
      that
        .$post("api/SysNews/List", {
          State: true,
          Type: "首页小轮播图",
          IsHome: true,
          Sort:'Sort ASC'
        })
        .then(function (res) {
          console.log(res);
          let data = res.Obj;
          data.forEach((v) => {
            if (v.Img) {
              v.Img = that.urlOA + v.Img.substr(1);
            }
          });
          that.smallLB = data;
        });
    },
    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },
    //   招聘详情
    getZPInfor(id) {
      this.$router.push({ path: "/RecruitmentDetails", query: { SysID: id } });
    },
    //   服务详情
    getServeInfor(id) {
      this.$router.push({ path: "/serviceDetails", query: { SysID: id } });
    },
    //   载体详情
    GetBuildingIn(id) {
      this.$router.push({ path: "/carrierDetails", query: { SysID: id } });
    },
    getGinfor(item) {
      this.$router.push({ path: "/Gdetile", query: { SysID: item } });
    },
    getuserCenter() {
      this.$router.push("/personalCenter");
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    handleClick(e) {
      var catename = "";
      if (e != null && e.label != "最新资讯") {
        catename = e.label;
      }
      let that = this;
      that
        .$post("/api/News/List", {
          State: true,
          CateName: catename,
          Sort: "PublishTime desc",
          PageSize: 4,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewsList = data.Obj;
          that.NewsList.forEach((v) => {
            //  debugger
            if (v.FirstImgUrl) {
              v.FirstImgUrl = that.urlOA + v.FirstImgUrl.substr(1);
            }
            v.StarTime = timeFormat.timeFormat(v.StarTime, 1);
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 1);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    InformationDetails(item) {
      let type = item.Type;
      console.log(type);
      if (type == 1) {
        this.$router.push({
          path: "/ZMBpolicyDetail",
          query: { SysID: item.SysID },
        });
      } else {
        this.$router.push({
          path: "/InformationDetails",
          query: { SysID: item.SysID },
        });
      }
    },
    GetNewNewsList() {
      let that = this;
      that
        .$post("/api/News/List", {
          State: true,
          Sort: "PublishTime desc",
          PageSize: 8,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          let NewNewsList = data.Obj;
          NewNewsList.forEach((v) => {
            if (v.FirstImgUrl) {
              v.FirstImgUrl = that.urlOA + v.FirstImgUrl.substr(1);
            }
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 3);
          });
          that.NewNewsList = NewNewsList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCateList() {
      let that = this;
      that
        .$post("/api/Dictionary/List", {
          State: true,
          Type: "NewsCate",
          Sort: "Sort ASC",
        })
        .then(function (data) {
          console.log(data);
          data.Obj.forEach((v) => {
            if (v.Name != "政策申报") {
              that.CateList.push(v);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    GetInteractionList() {
      let that = this;
      that
        .$post("/api/Interaction/List", {
          State: "true",
          CState: true,
          Type: 0,
          Sort: "PublishTime desc",
          PageSize: 4,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          that.InteractionGList = data.Obj;
          that.InteractionGList.forEach((v) => {
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });

      that
        .$post("/api/Interaction/List", {
          State: "true",
          CState: true,
          Type: 1,
          Sort: "PublishTime desc",
          PageSize: 4,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.InteractionXList = data.Obj;
          that.InteractionXList.forEach((v) => {
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetBuildingList() {
      let that = this;
      that
        .$post("/api/Building/List", {
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.BuildingList = data.Obj;

          that.BuildingList.forEach((v) => {
            if (v.FileList.length > 0) {
              v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
              if (v.FileList[0].URL) {
                v.FileList[0].URL = that.urlOA + v.FileList[0].URL.substr(1);
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetServeList() {
      let that = this;
      that
        .$post("/api/Serve/List", {
          State: true,
          CState: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          IsHome: true,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.ServeList = data.Obj;
          that.ServeList.forEach((v) => {
            if (v.Logo) {
              v.Logo = that.urlOA + v.Logo.substr(1);
            }

            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          State: true,
          CState: 1,
          Sort: "CreateOn desc",
          PageSize: 12,
          Type: 1,
          IsHome: "true",
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.CompanyList = data.Obj;
          that.CompanyList.forEach((v) => {
            if (v.CompanyLogo) {
              v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    GetNewCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          CState: 1,
          State: true,
          Sort: "CreateOn desc",
          PageSize: 8,
          Type: 1,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewCompanyList = data.Obj;
          that.NewCompanyList.forEach((v) => {
            if (v.CompanyLogo) {
              v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 最新招聘
    GetNewZPList() {
      let that = this;
      that
        .$post("/api/Advertise/List", {
          State: true,
          CState: true,
          Sort: "CreateOn desc",
          PageSize: 5,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewZPList = data.Obj;
          that.NewZPList.forEach((v) => {
            if (v.CompanyLogo) {
              v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var that = this;
    console.log(this.windowWidth);
    //     that.imgLOad();

    // window.addEventListener("resize", () => {
    //   this.bannerHeight = this.$refs.bannerHeight[0].height;
    // });

    // that.imgLOad();
    // console.log(document.documentElement);
    let windowWidth = document.documentElement.clientWidth;
    console.log(windowWidth);
    if (windowWidth && windowWidth < 500) {
      that.bannerHeightl = "150";
    } else {
      that.bannerHeightl = "270";
    }
    // <!--把window.onresize事件挂在到mounted函数上-->

    that.GetCateList();
    that.handleClick();
    that.GetInteractionList();
    this.GetBuildingList();
    that.GetServeList();
    that.GetCompanyList();
    that.GetNewCompanyList();
    that.GetNewNewsList();
    that.getLBsmail();
    that.GetNewZPList();
    that.getLCT();
  },
};
</script>


<style scoped  lang="scss">
.textleft .bannerImg {
  height: 270px;
  width: 100%;
  // object-fit: none;
}
#accordionColumn1 {
  float: left;
  width: 48%;
}
#accordionColumn2 {
  float: right;
  width: 48%;
}
.textleft::v-deep .el-tabs__nav-next,
.textleft::v-deep .el-tabs__nav-prev {
  line-height: 60px;
}
.sr-card {
  float: left;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  border-radius: 3px;
  box-shadow: 0 2px 3px #ddd;
  width: 276px;
  margin-left: 34px;
  margin-top: 20px;
  border: 1px solid #ddd;
  cursor: pointer;
  .sr-card-image {
    height: 154px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .sr-card-contents {
    background-color: #fcfcfc;
    padding: 8px;
    h3 {
      height: 48px;
      font-size: 16px;
      color: #323232;
      font-weight: bold;

      line-height: 1.6;
      margin-bottom: 8px;
    }
  }
  .sr-card-content {
    height: 142px;
    padding: 8px;
    h3 {
      font-size: 16px;
      color: #323232;
      font-weight: bold;

      line-height: 1.6;
      margin-bottom: 8px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .tags {
      line-height: 22px;
      font-size: 14px;
      overflow: hidden;
      color: #ff6633;
      margin-bottom: 8px;
      span {
        padding-right: 6px;
        height: 20px;
        font-size: 12px;
      }
    }
    p {
      color: #666;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
    }
  }
  .sr-card-footer {
    background-color: #f3f7fb;
    padding: 8px;

    .fl {
      float: left;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid #f8f8f8;
    }
    .company {
      margin-top: 10px;
      font-size: 14px;
      height: 40px;
      display: flex;
      justify-items: center;
      line-height: 20px;
      padding-left: 10px;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.sr-card:hover {
  box-shadow: 0 6px 40px #ddd;
}
.sr-row {
  margin-left: -18px;
  margin-right: -18px;
  margin-bottom: 30px;
}
.sr-row:before,
.sr-row:after {
  content: "";
  display: table;
  clear: both;
}
.row_item {
  float: left;
  cursor: pointer;
  display: block;
  width: 172px;
  height: 100px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  margin-left: 36px;
  box-sizing: border-box;
  overflow: hidden;
  img {
    float: left;
    width: 80%;
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
// 内容区
.textleft::v-deep .el-tabs__item {
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 18px;
  font-weight: 800;
  color: #303133;
  position: relative;
}
.textleft::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
.textleft::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
// banner
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__container {
  // height: 200px;
  height: 1000px !important;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  // padding-top: 30px;
  overflow: hidden;
}
.textleft {
  float: left;
  width: 900px;
}
.textright {
  float: left;
  width: 300px;
  // height: 600px;
  margin-left: 20px;
  h4 {
    border-left: 4px solid #3273dc;

    padding-left: 10px;
  }
}
.item {
  position: relative;
  height: 160px;
  width: 100%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 160px;
    width: 280px;
  }
  .noimgbox {
    position: absolute;
    left: 310px;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 6px;
    box-sizing: border-box;
    h3 {
      position: absolute;
      box-sizing: border-box;
      width: 540px;
      top: 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    p {
      position: absolute;
      top: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .itembottom {
      position: absolute;

      height: 40px;
      bottom: 0;
      line-height: 40px;
      font-size: 14px;
      span {
        padding: 3px 20px;
        background-color: #ff6633;
        margin-right: 20px;
        color: #fff;
        font-size: 14px;
        border-radius: 1px;
      }
    }
  }
  .newsbox {
    position: absolute;
    left: 310px;
    height: 100%;
    width: 550px;
    padding: 6px;
    box-sizing: border-box;
    h3 {
      position: absolute;
      top: 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    p {
      position: absolute;
      top: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .itembottom {
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      line-height: 40px;
      font-size: 14px;
    }
  }
}
.items {
  color: #000;

  font-size: 0.8rem;
  line-height: 36px;
  height: 36px;

  overflow: hidden;

  span {
    float: left;

    line-height: 36px;
    height: 36px;
    margin-left: 3px;
  }
  i {
    float: right;
    font-style: normal;
  }
}
.items:hover,
.item h3:hover,
.item p:hover {
  color: #2b60a5;
}
.zptitle {
  text-align: center;
  color: #2b60a5;
  font-weight: 600;
}
.itemZp {
  position: relative;
  font-size: 0.8rem;
  line-height: 36px;
  height: 60px;
  padding: 5px 0;
  margin: 6px 0;
  box-sizing: border-box;

  img {
    float: left;
    width: 50px;
    // margin-top: 6px;
   height: 50px;
     object-fit: scale-down;
  }
}
.itemlct {
  height: 80px;
  width: 100%;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;

    // object-fit: contain;
  }
}
.companinfor {
  position: absolute;
  width: 100%;
  height: 50px;
  padding: 0 50px 0 60px;
  box-sizing: border-box;
 
  span {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  i {
    position: absolute;

    height: 14px;
    font-style: normal;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    line-height: 14px;
    // margin-right: -60px;
    // right: -30px;
    font-size: 13px;
    color: #ff6633;
    font-weight: 550;
  }
}
// 底部
.footbox {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 300px;
  width: 100%;
  background-color: #343130;
}
.foot_left {
  width: 400px;
  height: 200px;
  // background-color: #eee;
  .left_top {
    float: left;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    color: #717071;
    box-sizing: border-box;
  }
  .left_bottom span {
    color: #717071;
    float: left;
    cursor: pointer;
    width: 50%;
    height: 40px;
    line-height: 40px;
  }
  .left_bottom span :hover {
    color: #fff !important;
  }
}
.foot_right {
  padding-top: 60px;
  box-sizing: border-box;
  width: 600px;
  height: 200px;
  // background-color: #eee;
  p {
    color: #717071;
    // height: 40px;
    line-height: 30px;
  }
}
.aaa {
  display: none;
}

@media (max-width: 500px) {
  .textleft .bannerImg {
    height: 150px;
    width: 100%;
    // object-fit: none;
  }
  #accordionColumn1,
  #accordionColumn2 {
    float: left;
    width: 90%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .textleft::v-deep .el-tabs__item {
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    line-height: 60px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 800;
    color: #303133;
    position: relative;
  }
  .textleft::v-deep .el-tabs__nav-wrap::after {
    height: 1px;
  }
  .textleft::v-deep .el-tabs__item.is-active {
    color: #409eff;
  }
  .textleft::v-deep .el-tabs__active-bar {
    width: 45px !important;
  }
  .main .textbox {
    width: 100%;
  }
  .textleft {
    float: left;
    width: 100%;
  }
  .textright {
    float: left;
    width: 100%;
    // height: 600px;
    margin-left: 0;
  }
  .box-card {
    width: 96vw;
    margin-left: 2vw;
  }
  .item {
    height: 60px;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      top: 0;
      height: 60px;
      width: 24%;
    }
    .newsbox {
      position: absolute;
      left: 25%;
      height: 100%;
      width: 75%;
      padding: 2px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        text-overflow: -o-ellipsis-lastline;
        width: 240px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
      p {
        position: absolute;
        top: 20px;
        height: 20px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        top: 40px;
        line-height: 20px;
        font-size: 10px;
      }
    }

    .noimgbox {
      position: absolute;
      left: 25%;
      height: 100%;
      width: 75%;
      padding: 2px;
      box-sizing: border-box;
      h3 {
        position: absolute;
        top: 0;
        text-overflow: -o-ellipsis-lastline;
        width: 240px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
      }
      p {
        position: absolute;
        top: 20px;
        height: 20px;
        line-height: 20px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 12px;
      }
      .itembottom {
        position: absolute;
        height: 20px;
        top: 40px;
        line-height: 20px;
        font-size: 10px;
        span {
          font-size: 12px;
          padding: 0 4px;
          margin-right: 10px;
        }
      }
    }
  }
  .sr-card {
    width: 96%;
    margin-left: 2%;
    box-sizing: border-box;
  }
  .row_item {
    width: 40%;
    height: 70px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    margin-bottom: 20px;
    margin-left: 7%;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .textleft::v-deep .el-tabs__nav-next,
  .textleft::v-deep .el-tabs__nav-prev {
    line-height: 60px;
    font-size: 18px;
  }
  .sr-row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .mybox {
    padding: 10px 0px 6px 3px !important;
    a{
      font-size: 14px !important;

    }
    .moneyss{
      font-size: 13px !important;
    }
    .uutime{
       font-size: 13px !important;
    }
    .comnames{
      font-size: 13px !important;
    }
    .myrighttime{
      font-size: 13px !important;
    }
  }

  .itemlct {
  height: 80px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;

    // object-fit: contain;
  }
}
.comnames{
  width: 100% !important;
  margin-bottom: 10px;
  padding-right: 10px !important;
  box-sizing: border-box;
}
.moneyss{
  margin-left: 10px !important;
}
.myrighttime{
  margin-right: 0 !important;
}
// .sr-card .sr-card-content h3{
//   font-size: 14px;
// }
.smallbanner{
  display: none;
}
}
</style>